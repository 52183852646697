





















































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import CalendarIcon from '@/app/ui/assets/icon_calendar_2.vue'
import Loading from '@/app/ui/components/Loading/index.vue';
import ModalSuccess from '../../components/Modals/ModalSuccess/index.vue'
import controller from '@/app/ui/controllers/PayrollBonusController'
import { Utils } from '@/app/infrastructures/misc/Utils'
import dayjs from 'dayjs'
import dayjsID from 'dayjs/locale/id'
import { EnumBonusStatus, EnumModalMessage } from '@/app/infrastructures/misc/Constants/bonusPayroll'

interface Bonus {
  id: number
  courierId: number
  courierName: string
  phoneNumber: string
  totalBonusPerformance: number
  totalBonusPickup: number
  totalBonusCaptain: number
  totalBonusPersentage: number
  totalBonus: number
  status: string
  month: number
  year: number
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
}

@Component({
  components: {
    TextInput,
    Button,
    CalendarIcon,
    ModalSuccess,
    Loading
  },
})
export default class BonusDetailPage extends Vue {
  controller = controller
  modalSuccessVisible = false
  modalMessage = ''

  form = {
    createdAt: '-',
    createdBy: '-',
    updatedAt: '-',
    updatedBy: '-',
    bonusID: '-',
    driverID: '-',
    driverName: '-',
    phoneNumber: '-',
    totalBonusPerformance: '0',
    totalBonusPickup: '0',
    totalBonusCaptain: '0',
    totalBonusPersentage: '0',
    totalBonus: '0',
    status: '-',
    bonus_date: '-',
  }

  created() {
    this.fetchData()
  }

  get isApproveButtonDisabled(): boolean {
    if (
      this.form.status === EnumBonusStatus.APPROVE ||
      this.form.status === EnumBonusStatus.APPROVE_WITH_ADJUSTMENT
    ) {
      return true
    }
    return false
  }

  get isRejectButtonDisabled(): boolean {
    if (
      this.form.status === EnumBonusStatus.APPROVE ||
      this.form.status === EnumBonusStatus.APPROVE_WITH_ADJUSTMENT ||
      this.form.status === EnumBonusStatus.REJECT
    ) {
      return true
    }
    return false
  }

  private fetchData(): void {
    controller.getDetail(this.$route.params.id)
  }

  private setDisplayCurrency(amount: string): string {
    const res = Number(amount.replace('.00', ''))
    return `Rp${Utils.currencyDigit(res)},00`
  }

  private onApprove(): void {
    this.modalMessage = EnumModalMessage.APPROVE
    this.onUpdate(EnumBonusStatus.APPROVE)
  }

  private onApproveWithAdjust(): void {
    this.modalMessage = EnumModalMessage.APPROVE_WITH_ADJUSTMENT
    this.onUpdate(EnumBonusStatus.APPROVE_WITH_ADJUSTMENT)
  }

  private onReject(): void {
    this.modalMessage = EnumModalMessage.REJECT
    this.onUpdate(EnumBonusStatus.REJECT)
  }

  private onUpdate(status: string): void {
    const payload = {
      ids: [Number(this.$route.params.id)],
      status: status
    }

    controller.update(payload)
  }

  private onCloseModal(): void {
    this.modalSuccessVisible = false
    if (this.modalMessage === EnumModalMessage.APPROVE_WITH_ADJUSTMENT) {
      this.$router.push('/payroll/manual-adjust-saldo')
    }
    this.modalMessage = ''
  }

  private formatDateTime(dateTime?: string): string {
    if (!dateTime) return '';
    return Utils.formatTimeZone(
      Utils.formatDateWithIDLocale(dateTime, 'DD MMMM YYYY, HH:mm Z'),
    )
  }

  private isUpdatedDateValid(date: string | undefined) {
    if (date && this.formatDateTime(date) !== this.formatDateTime('0001-01-01T00:00:00Z')) {
      return true
    }
    return false
  }

  @Watch('controller.dataBonus')
  setBonusData(data: Bonus): void {
    const res = {
      createdAt: this.formatDateTime(data.createdAt),
      createdBy: data.createdBy || '',
      updatedAt: this.isUpdatedDateValid(data.updatedAt) ? this.formatDateTime(data.updatedAt) : '-',
      updatedBy: data.updatedBy || '-',
      bonusID: String(data.id),
      driverID: String(data.courierId),
      driverName: String(data.courierName),
      phoneNumber: String(data.phoneNumber),
      totalBonusPerformance: data.totalBonusPerformance ? String(data.totalBonusPerformance) : '0',
      totalBonusPickup: data.totalBonusPickup ? String(data.totalBonusPickup) : '0',
      totalBonusCaptain: data.totalBonusCaptain ? String(data.totalBonusCaptain) : '0',
      totalBonusPersentage: data.totalBonusPersentage ? String(data.totalBonusPersentage) : '0',
      totalBonus: data.totalBonus ? String(data.totalBonus) : '0',
      status: String(data.status),
      bonus_date:
        data.month
        ? `${dayjs().month(data.month - 1).locale(dayjsID).format('MMMM')} ${data.year}`
        : '-',
    }

    this.form = res
  }

  @Watch('controller.isEditBonusPayrollStatusSuccess')
  setEditBonusPayrollStatusSuccess(data: boolean): void {
    if (data) {
      this.modalSuccessVisible = true
      this.fetchData()
    }
  }

  beforeDestroy(): void {
    this.controller.setEditBonusPayrollStatusSuccess(false)
  }
}
